@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  position: relative;

  @media (--viewportMedium) {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 26px;
  width: calc(100% - 52px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorLight);
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: 560px;

    border-radius: var(--borderRadius);
  }
}

.viewPhotos {
  /* For now remove it */
  display: none;

  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 26px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 48px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mapContentContainer {
  width: 100%;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto 60px;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 48px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 24px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 48px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 4px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
  color: var(--matterColorAnti);
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;

  margin-bottom: 34px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 48px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 48px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionMiniSessionFeatures {
  padding: 0 24px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 48px;
  }
}

.miniSessionFeaturesTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;

  margin-top: 0;
  margin-bottom: 24px;
}

.sectionReviews {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;

  margin: 48px 0 24px;
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 48px;
  }
}

.sectionHostNoReviews {
  margin-bottom: 0;
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  margin: 0 0 24px;
}

.enquiryModalContent,
.discountModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm,
.discountForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper,
.discountSubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 100%;
  max-height: 300px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
    max-height: 640px;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.headingPrice {
  /* color: var(--marketplaceColor); */
}

.returnLink {
  display: inline-flex;
  align-items: center;
  padding: 34px 24px 0;

  @media (--viewportMedium) {
    padding: 0;
  }

  & a {
    @apply --marketplaceSmallFontStyles;
    color: var(--matterColorAnti);
    font-weight: var(--fontWeightRegular);
    line-height: 1;

    /* Animation */
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    & a {
      color: var(--matterColor);
    }

    & .returnIcon {
      /* Fill */
      fill: var(--matterColor);
      stroke: var(--matterColor);
    }
  }
}

.returnIcon {
  margin-bottom: 1px;
  margin-right: 8px;
  stroke-width: 0;

  /* Fill */
  fill: var(--matterColorAnti);
  stroke: var(--matterColorAnti);

  /* Animation */
  transition: all 0.2s ease-in-out;
}

.imageLabelContainer {
  /* Layout */
  display: flex;

  /* Position */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  max-width: 1056px;
  width: 100%;

  margin: 0 auto;
  padding: 0 24px;

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.imageLabel {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);

  /* Style */
  background-color: var(--matterColorLight);
  padding: 6px 14px 4px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.imageLabelAuthor {
  color: var(--matterColorLink);

  &:hover {
    border-bottom: 1px solid var(--matterColorLink);
  }
}

.reviewStarContainer {
  background-color: var(--failColor);
  justify-content: space-between;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.reviewStar {
  width: 12px;
  fill: var(--matterColorLight);
}

.accordion {
  margin-top: 16px;
}

.carousel {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
